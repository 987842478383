<template>
    <div class="contract-container">
        <StackRouterHeaderBar
            :left-button-handler="back"
            :left-button="`close`"
            :show-title="true"
            title="전자계약서 서명"
        />
        <div class="contract-container-main" :class="signStatus.mode">
            <div class="content">
                <ContractTruthV2
                    :signStatus="signStatus"
                    :contract-version="contractVersion"
                    :sign="sign"
                    :today="today"
                />
            </div>
        </div>
        <ContractProgressBtn v-if="mode === 'create'" @submitContract="submitContract" :signStatus="signStatus" />
    </div>
</template>

<script>
import { v2 } from '@/assets/constants/contract'
import userContractService from '@/services/user-contract'
import ContractProgressBtn from '@/routes/my-page/components/contract/ContractProgressBtn'

export default {
    name: 'MakeTruthContractPage',
    data: () => ({
        sign: null,
        imgBlob: null,
        signStatus: {
            nowIdx: 0,
            clicked: [false],
            ids: ['truth-sign'],
            mode: 'create',
        },
        userContract: null,
    }),
    async mounted() {
        try {
            const { data } = await userContractService.get(this.me.id, (this.me.user_contract || {}).id)
            this.userContract = data || {}
        } catch (e) {
            this.userContract = {}
        }
        this.signStatus.mode = this.mode
        if ((this.userContract || {}).photo_url) {
            this.sign = this.userContract.photo_url
        }
        this.$registerBackHandler(this.back)
        this.$bus.$on('getUserSign', this.getUserSign)
    },
    beforeDestroy() {
        this.$unregisterBackHandler()
        this.$bus.$off('getUserSign')
    },
    components: {
        ContractProgressBtn,
        ContractTruthV2: () =>
            import(/* webpackPrefetch: true */ '@/routes/my-page/components/contract/ContractTruthV2'),
    },
    computed: {
        today() {
            const date = this.mode === 'create' ? new Date() : new Date(this.userContract.complete_time)

            return {
                y: date.getFullYear(),
                m: date.getMonth() + 1,
                d: date.getDate(),
            }
        },
        contractVersion() {
            return v2
        },
        me() {
            return this.$store.getters.me
        },
        mode() {
            if ((this.userContract || {}).plan_type === 'prepaid' && (this.userContract || {}).version < 3) {
                return this.userContract.is_complete ? 'import' : 'create'
            }
            return (this.userContract || {}).truth_complete ? 'import' : 'create'
        },
    },
    methods: {
        async back() {
            if (this.sign && this.signStatus.mode === 'create') {
                const idx = await this.$modal.basic({
                    body: '서명을 그만두시겠습니까? 서명한 내용이 저장되지 않습니다',
                    preventCloseOnMousedownMask: true,
                    buttons: [
                        {
                            label: '아니오',
                            class: 'btn-default',
                        },
                        {
                            label: '네',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (!idx) return
            }
            this.$stackRouter.pop()
        },
        getUserSign({ dataUrl, imgBlob }) {
            this.sign = dataUrl
            this.imgBlob = imgBlob
        },
        async submitContract() {
            try {
                const payload = new FormData()
                payload.append('truth_complete', true)

                // 이건 싸인 제출
                const { res, data } = await userContractService.update(
                    this.me.id,
                    (this.me.user_contract || {}).id,
                    payload
                )
                if (res === 'success') {
                    // this.$toast.success('작성 완료! 계약서는 마이페이지에서 확인할 수 있습니다.')
                    this.$set(this.me, 'user_contract', data)
                    this.$stackRouter.pop()
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.contract-container {
    line-height: 24px;
    font-size: 14px;

    .contract-container-main {
        padding: 16px;
        height: calc(100vh - 110px);
        overflow-y: auto;

        &.import {
            height: calc(100vh - 52px);
        }
    }
}
</style>
